import axios from 'axios'
import { API_ROUTES } from '../routes'

export const deleteAccountingData = async (
  customerId: string,
  year: string
) => {
  return await axios.delete(
    API_ROUTES.CUSTOMER + customerId + '/accounting/' + year
  )
}
